import React from 'react';
import CatalunLogoWhite from 'images/catalun-logo-white.svg';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/pages/mailing-list-success.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village - Success!</title>
    </PageHead>
  );
}

function MailingListSuccessPage() {
  return (
    <Page>
      {/* !!--NEXT1: Fix this entire rushed setup. Multiple classes, everything on the
        /mailing list page. See if mailchimp can forward request params. */}
      <div className={styles.MailingList}>
        <section className={styles.pageCenter}>
          <img src={CatalunLogoWhite} className={styles.centerLogo} alt="Catalün Village" />
          <h2 className={styles.smallHeading}>Success.</h2>
          <article>
            <p className={styles.subtext}>Thanks for signing up.</p>
          </article>
        </section>
      </div>
    </Page>
  );
}

export default MailingListSuccessPage;
